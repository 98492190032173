
.highbox {
    width: 100%;
    background: #fefefe;
    line-height: 30px;
    overflow: hidden;
    padding: 20px;
    margin-bottom: 20px;

    box-shadow: 0px 3px 20px 0 rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.1);
    -o-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
    -ms-box-shadow: 0 3px 20px 0px rgba(0, 0, 0, 0.1);
}

.end {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}


.header {
    display: flex;
    justify-content: space-between;
    top: 0;
    z-index: 10;
}

.home_text {
    line-height: 200%;
    font-weight: 500;
}
@media screen and (max-width : 750px) {
    .end {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 20px;
    }

}

@media screen and (min-width : 1110px) {

    .home_container {
        display: flex;
        justify-content: space-around;
        height: "100%";
        overflow: hidden;
        z-index: -1;
    }

    .title{
        position: relative;
        grid-area: title;
        margin-bottom: 10px;
    }

    .home_text {
        position: relative;
        grid-area: home_text;
        text-align: left;
    }

    .home_slide {
        position: relative;
        grid-area: home_slide;
        width: 450px;
        margin-left: 30px;
    }

    .footer {
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width : 1110px) {
    .home_container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        z-index: -1;
    }

    .title{
        margin-bottom: 50px;
    }

    .home_text {
        width: auto;
    }

    .home_slide {
        display: none;
    }

    .footer {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}

