
.slide-container{
    background: black;
    border: 5px solid rgba(186,186,186,0.95);
    border-radius: 10px;
    height: 400px;
    text-align: center;
    align-self: center;
    overflow: hidden;
}
.indicator {
    top: 0;
    cursor: pointer;
    padding: 10px;
    text-align: center;
    border: 1px #666 solid
}

.indicator.active {
    top: 0;
    color: #fff;
    background: #666;
}